import * as React from 'react';

const errorMap = {
  404: 'You just hit a route that doesn\'t exist... the sadness.',
};

interface Props {
  errorCode: keyof typeof errorMap;
}

const OopsPage = ({ errorCode }: Props) => (
  <div>
    <h2>Oops</h2>
    <p>{errorMap[errorCode]}</p>
  </div>
);

export default OopsPage;
